<template>
  <v-row no-gutters>
    <v-col cols="12" md="9" class="pl-2 mt-2 pr-2">
      <v-row no-gutters class="mr-2 ml-2 mt-2">
        <v-col cols="5" md="2">
          <v-menu
            v-model="fromMenu"
            full-width
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                readonly
                v-on="on"
                hide-details
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromPicker"
              @input="fromMenu = false"
              color="primary"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5" md="2" class="pl-2">
          <v-menu
            v-model="toMenu"
            full-width
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                readonly
                v-on="on"
                hide-details
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toPicker"
              @input="toMenu = false"
              color="primary"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" md="2" class="pl-1">
          <v-combobox
            v-model="allcashtype"
            :items="allcashtypeList"
            label="Cash Type"
            item-text="typeName"
            return-object
            required
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="6" md="2" class="pl-1">
          <v-combobox
            v-model="branch"
            :items="branchList"
            label="Branch"
            item-text="description"
            return-object
            required
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="2" md="1" class="pl-1 pt-1">
          <v-btn icon class="primary" @click="billListMethod()" small>
            <v-icon color="white"> search </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12" >
          <span class="red--text">Out = {{ totalOut | doubleFormat }} </span> , 
          <span class="primary--text">In = {{ totalIn | doubleFormat }} </span> , 
          <span class="blue--text">Balance = {{ totalBalance | doubleFormat }}</span>
        </v-col>
      </v-row>
      <v-simple-table
        :fixed-header="true"
        dense
        class="elevation-1"
        height="77vh"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center white--text primary">No.</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Date</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Customer</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Type Name</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Out Price</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Out Com</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">In Price</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">In Com</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">P&L</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Remark</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Credit</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Role</th>
              <th class="text-center white--text primary">
                <v-divider vertical></v-divider>
              </th>
              <th class="text-center white--text primary">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in billList"
              :key="index"
              @click="selectOneClick(item)"
              :style="{
                backgroundColor:
                  item.billId == selectedOne.billId ? '#def3ff' : 'transparent',
              }"
            >
              <td class="text-center">{{ index + 1 }}</td>

              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">{{ item.receivedDate }}</td>

              <td class="text-center"><v-divider vertical></v-divider></td>
              <td>{{ item.brocker.name }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td>
                {{ item.tranList[0].typeName }}({{ item.tranList[0].unit }})
              </td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-right" v-if="item.tranList[0].amount > 0"></td>
              <td class="text-right red--text" v-else>
                <h4>{{ item.tranList[0].amount | doubleFormat }}</h4>
              </td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-right" v-if="item.tranList[0].amount > 0"></td>
              <td class="text-right red--text" v-else>
                <h4>{{ item.tranList[0].commission | doubleFormat }}</h4>
              </td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-right" v-if="item.tranList[0].amount < 0"></td>
              <td class="text-right primary--text" v-else>
                <h4>{{ item.tranList[0].amount | doubleFormat }}</h4>
              </td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-right" v-if="item.tranList[0].amount < 0"></td>
              <td class="text-right primary--text" v-else>
                <h4>{{ item.tranList[0].commission | doubleFormat }}</h4>
              </td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td
                class="text-right"
                v-if="item.tranList[0].totalAmount == 0"
              ></td>
              <td class="text-right blue--text" v-else>
                <h4>{{ item.tranList[0].totalAmount | doubleFormat }}</h4>
              </td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">{{ item.tranList[0].remark }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center red--text" v-if="item.chkCredit">
                အကြွေး
              </td>
              <td class="text-center green--text" v-else>ရှင်းပြီး</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">{{ item.userName }}</td>
              <td class="text-center"><v-divider vertical></v-divider></td>
              <td class="text-center">
                <v-btn small icon color="green" @click="clickHistory(item, index)">
                  <v-icon>history</v-icon></v-btn
                >
                <v-btn v-if="uiAccept.msedit" small icon color="green" @click="clickEdit(item, index)">
                  <v-icon>edit</v-icon></v-btn
                >
                <v-btn v-if="uiAccept.msdelete" small icon color="red" @click="clickDelete(item)">
                  <v-icon>delete</v-icon></v-btn
                >
              </td>
            </tr>
            <v-divider></v-divider>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12" md="3" class="mt-10">
      <v-row class="align-center login-full" no-gutters>
        <v-col>
          <v-card outlined class="mr-2 pl-2 pr-2">
            <v-row no-gutters>
              <v-col cols="12" md="12" class="mt-2">
                <v-menu
                  v-model="receiveMenu"
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="receivedDate"
                      label="Receive Date"
                      readonly
                      v-on="on"
                      hide-details
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="receivePicker"
                    @input="receiveMenu = false"
                    color="primary"
                    :max="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" md="4">
                <v-checkbox label="အကြွေး" v-model="chkCredit"></v-checkbox>
              </v-col>
              <v-col cols="9" md="8" class="mt-2">
                <v-combobox
                  v-model="brocker"
                  :items="brockerList"
                  label="Customer"
                  item-text="name"
                  return-object
                  required
                  dense
                  outlined
                  filled
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon color="primary" @click="brockerDialogTrue = true"
                        >add_circle</v-icon
                      >
                    </v-slide-x-reverse-transition>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6" md="6" class="ml-3">
                <span class="red--text"
                  >
                    {{ outTotalBalance | doubleFormat }} ({{ cashtype.unit }})
                  </span
                >
              </v-col>
              <v-col cols="6" md="5" class="ml-3">
                <span class="red--text"
                  >
                    {{ inTotalBalance | doubleFormat }} ({{ incashtype.unit }})
                  </span
                >
              </v-col>
              <v-col cols="6" md="6">
                <v-combobox
                  filled
                  v-model="cashtype"
                  :items="cashtypeList"
                  label="Out Type"
                  item-text="typeName"
                  return-object
                  required
                ></v-combobox>
              </v-col>
              <v-col cols="6" md="6" class="pl-3">
                <v-combobox
                  filled
                  v-model="incashtype"
                  :items="incashtypeList"
                  label="In Type"
                  item-text="typeName"
                  return-object
                  required
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" md="6">
                <v-text-field
                  v-model.number="outOneUnit"
                  label="Out OneUnit"
                  type="number"
                  dense
                  outlined
                  filled
                />
              </v-col>
              <v-col cols="6" md="6" class="pl-3">
                <v-text-field
                  v-model.number="inOneUnit"
                  label="In OneUnit"
                  type="number"
                  dense
                  outlined
                  filled
                />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  v-model.number="outUnitAmount"
                  label="Out UnitAmount"
                  type="number"
                  dense
                  outlined
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="6" md="6" class="pl-3">
                <v-text-field
                  v-model.number="inUnitAmount"
                  label="In UnitAmount"
                  type="number"
                  dense
                  outlined
                  filled
                  disabled
                />
              </v-col>
              <v-col cols="6" md="6" class="green--text">
                <!-- <v-text-field
                  @keyup.enter="enterOut()"
                  ref="refOut"
                  v-model.number="outBalance"
                  label="Out Amount"
                  type="number"
                  dense
                  outlined
                  filled
                  disabled
                /> -->
                <h4>{{outBalance|doubleFormat}}(mmk)</h4>
              </v-col>
              <v-col cols="6" md="6" class="pl-3 green--text">
                <!-- <v-text-field
                  @keyup.enter="enterIn()"
                  ref="refIn"
                  v-model.number="inBalance"
                  label="In Amount"
                  type="number"
                  dense
                  outlined
                  filled
                  disabled
                /> -->
                <h4>{{inBalance|doubleFormat}}(mmk)</h4>
              </v-col>
              <v-col cols="6" md="6" class="mt-2">
                <v-text-field
                  @keyup.enter="enterOutCommission()"
                  ref="refOutCommission"
                  v-model.number="outCommission"
                  label="Commission"
                  type="number"
                  dense
                  outlined
                  filled
                />
              </v-col>
              <v-col cols="6" md="6" class="pl-3 mt-2">
                <v-text-field
                  @keyup.enter="enterInCommission()"
                  ref="refInCommission"
                  v-model.number="inCommission"
                  label="Commission"
                  type="number"
                  dense
                  outlined
                  filled
                />
              </v-col>
              <v-col cols="8" md="8">
                <v-textarea
                  label="Remark"
                  rows="2"
                  v-model="inRemark"
                  dense
                  outlined
                  filled
                ></v-textarea>
              </v-col>
              <v-col cols="4" md="4" class="text-right">
                <v-btn color="primary" @click="clickBillSave()">{{
                  saveOrupdate
                }}</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Delete </v-card-title>

        <v-card-text> Do you want to delete? </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="red" text @click="clickDeleteDialog(item)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="brockerDialogTrue" fullscreen scrollable>
      <v-sheet>
        <brockerDialog
          @closeBrockerDialog="closeBrocker()"
          :brockertype="brockertype"
        />
      </v-sheet>
    </v-bottom-sheet>
     <v-dialog v-model="historyDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> History </v-card-title>
      <v-row no-gutters class="ml-5">
        <v-col cols="4" md="4">
              Date 
        </v-col>
        <v-col cols="8" md="8">
              : {{this.history.date}}
        </v-col>
        <v-col cols="4" md="4">
              Out One Unit 
        </v-col>
        <v-col cols="8" md="8">
              : {{this.history.outOneUnit}}
        </v-col>
        <v-col cols="4" md="4">
              Out Unit Amount 
        </v-col>
        <v-col cols="8" md="8">
              : {{this.history.outUnitAmount}}
        </v-col>
        <v-col cols="4" md="4">
               In One Unit 
        </v-col>
        <v-col cols="8" md="8">
              : {{this.history.inOneUnit}}
        </v-col>
        <v-col cols="4" md="4">
               In Unit Amount 
        </v-col>
        <v-col cols="8" md="8">
              : {{this.history.inUnitAmount}}
        </v-col>
      </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import billService from "../service/BillService";
import customerService from "../service/CustomerService";
import brockerDialog from "@/components/BrockerDialog.vue";
export default {
  data: () => ({
    cashtype: {},
    cashtypeList: [],
    outBalance: 0,
    inBalance: 0,
    billList: [],
    selectedOne: {},
    fromPicker: new Date().toISOString().substr(0, 10),
    toPicker: new Date().toISOString().substr(0, 10),
    fromMenu: false,
    toMenu: false,
    fromDate: "",
    toDate: "",
    allcashtype: {},
    allcashtypeList: [],
    totalOutPrice: 0,
    totalInPrice: 0,
    deleteDialog: false,
    tempBill: {},
    saveOrupdate: "SAVE",
    file: null,
    incashtypeList: [],
    incashtype: {},
    billObj: {},
    outTranId: 0,
    inTranId: 0,
    outDate: "",
    inDate: "",
    outTotalBalance: "",
    inTotalBalance: "",
    brockerList: [],
    brocker: {},
    brockertype: "pay",
    brockerDialogTrue: false,
    outCommission: 0,
    inCommission: 0,
    inRemark: "",
    branchList: [],
    branch: {},
    chkCredit: false,
    receivePicker: new Date().toISOString().substr(0, 10),
    receiveMenu: false,
    receivedDate: "",
    totalIn: 0,
    totalOut: 0,
    totalBalance: 0,
    outOneUnit:0,
    inOneUnit:0,
    outUnitAmount:0,
    inUnitAmount:0,
    historyDialog:false,
    history:{},
    uiAccept:{},
  }),
  props: {},
  mounted: function () {
    this.uiAccept = this.$store.state.uiAccept;
    this.fromDate = this.formatDate(this.fromPicker);
    this.toDate = this.formatDate(this.toPicker);
    this.receivedDate = this.formatDate(this.receivePicker);
    this.getCashList();
    this.getPaymentListMethod();
    this.getBranchListMethod();
  },
  methods: {
    clickHistory:function(item, position){
      var outItem = {};
      var inItem = {};
      var tran = item.tranList[0];
      if (tran.amount < 0) {
        outItem = Object.assign({}, item);
        inItem = this.billList[Number(position) + 1];
      } else {
        outItem = this.billList[Number(position) - 1];
        inItem = Object.assign({}, item);
      }
      var outTran = outItem.tranList[0];
      var inTran = inItem.tranList[0];
      this.history.date = inTran.date;
      this.history.outOneUnit = outTran.oneUnit;
      this.history.outUnitAmount = outTran.unitAmount;
      this.history.inOneUnit = inTran.oneUnit;
      this.history.inUnitAmount = inTran.unitAmount;
        this.historyDialog = true;
    },
    getBranchListMethod: function () {
      billService
        .getBranch()
        .then((response) => {
          var tempObj = {};
          tempObj.branchId = 0;
          tempObj.description = "All";
          this.branchList.push(tempObj);
          this.branchList.push(...response);
          this.branch = this.branchList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    closeBrocker: function () {
      this.brockerDialogTrue = false;
      this.getPaymentListMethod();
    },
    getPaymentListMethod: function () {
      customerService
        .getBrocker(this.brockertype)
        .then((response) => {
          this.brockerList.splice(0);
          this.brockerList.push(...response);
          this.brocker = this.brockerList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    // clickImport: function () {
    //   var formData = new FormData();
    //   formData.append("file", this.$refs.file.files[0]);
    //   billService
    //     .billImport(formData)
    //     .then((response) => {
    //       this.deleteDialog = false;
    //       this.$swal({
    //         title: "Successful",
    //         text: "အောင်မြင်ပါ",
    //         type: "success",
    //         timer: 500,
    //       });
    //       this.billListMethod();
    //     })
    //     .catch((error) => {
    //       this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
    //     });
    // },
    changeImage: function (e) {
      let image = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.file = e.target.result;
      };
    },
    clickEdit: function (item, position) {
      this.receivedDate = item.receivedDate;
      this.receivePicker = this.formatPicker(this.receivedDate);
      this.chkCredit = item.chkCredit;
      this.brocker = item.brocker;
      this.billObj = Object.assign({}, item);
      var outItem = {};
      var inItem = {};
      var tran = item.tranList[0];
      if (tran.amount < 0) {
        outItem = Object.assign({}, item);
        inItem = this.billList[Number(position) + 1];
      } else {
        outItem = this.billList[Number(position) - 1];
        inItem = Object.assign({}, item);
      }
      var outTran = outItem.tranList[0];
      this.outTranId = outTran.transactionId;
      this.outDate = outTran.date;
      var inTran = inItem.tranList[0];
      this.inTranId = inTran.transactionId;
      this.inDate = inTran.date;
      var outType = {};
      outType.typeId = outTran.typeId;
      outType.typeName = outTran.typeName;
      outType.unit = outTran.unit;
      this.cashtype = Object.assign({}, outType);
      this.outOneUnit = outTran.oneUnit;
      this.outUnitAmount = outTran.unitAmount;
      this.outBalance = -outTran.amount;
      this.outCommission = outTran.commission;

      var inType = {};
      inType.typeId = inTran.typeId;
      inType.typeName = inTran.typeName;
      inType.unit = inTran.unit;
      this.incashtype = Object.assign({}, inType);
      this.inBalance = inTran.amount;
      this.inOneUnit = inTran.oneUnit;
      this.inUnitAmount = inTran.unitAmount;
      this.inCommission = inTran.commission;
      this.inRemark = inTran.remark;
      this.saveOrupdate = "Update";
    },
    clickDeleteDialog: function () {
      billService
        .deleteBill(this.tempBill)
        .then((response) => {
          this.deleteDialog = false;
          this.$swal({
            title: "Successful",
            text: "အောင်မြင်သည်",
            type: "success",
            timer: 500,
          });
          this.billListMethod();
          this.getBalanceMethod();
          this.inBalanceMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    clickDelete: function (item) {
      this.deleteDialog = true;
      this.tempBill = item;
    },
    formatDate(fromPicker) {
      const [year, month, day] = fromPicker.split("-");
      return `${day}-${month}-${year}`;
    },
    formatPicker(receivedDate) {
      const [day, month,year ] = receivedDate.split("-");
      return `${year}-${month}-${day}`;
    },
    selectOneClick: function (item) {
      this.selectedOne = item;
    },
    enterOut: function () {
      this.$nextTick(() => this.$refs.refIn.focus());
    },
    enterIn: function () {
      this.$nextTick(() => this.$refs.refOutCommission.focus());
    },
    enterOutCommission: function () {
      this.$nextTick(() => this.$refs.refInCommission.focus());
    },
    enterInCommission: function () {
      this.$nextTick(() => this.$refs.refOut.focus());
      this.clickBillSave();
    },
    getCashList: function () {
      billService
        .getCashTypes()
        .then((response) => {
          this.cashtypeList.push(...response);
          var tempObj = {};
          tempObj.typeId = 0;
          tempObj.typeName = "All";
          this.allcashtypeList.push(tempObj);
          this.allcashtypeList.push(...response);
          this.cashtype = this.cashtypeList[0];
          this.allcashtype = this.allcashtypeList[0];
          this.incashtypeList.push(...response);
          this.incashtype = this.incashtypeList[0];
          this.billListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    billListMethod: function () {
      billService
        .getBillLists(
          this.fromDate,
          this.toDate,
          this.allcashtype.typeId,
          this.branch.branchId
        )
        .then((data) => {
          this.billList.splice(0);
          //this.billList.push(...response);
          var tempSize = data.length;
          for (var i = 0; i < tempSize; i++) {
            var tempObj = data[i];
            let index = i + 1;
            if (index == tempSize) {
              this.totalOut = tempObj.totalOut;
              this.totalIn = tempObj.totalIn;
              this.totalBalance = tempObj.totalBalance;
            } else {
              this.billList.push(tempObj);
            }
          }
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    clickBillSave: function () {
      var tempTranList = [];
      var tempOutTran = {};
      tempOutTran.typeId = this.cashtype.typeId;
      tempOutTran.oneUnit = this.outOneUnit;
      tempOutTran.unitAmount = this.outUnitAmount;
      tempOutTran.amount = -this.outBalance;
      tempOutTran.commission = this.outCommission;
      tempOutTran.transactionId = this.outTranId;
      tempOutTran.date = this.outDate;
      tempTranList.push(tempOutTran);
      var tempInTran = {};
      tempInTran.typeId = this.incashtype.typeId;
      tempInTran.oneUnit = this.inOneUnit;
      tempInTran.unitAmount = this.inUnitAmount;
      tempInTran.amount = this.inBalance;
      tempInTran.commission = this.inCommission;
      tempInTran.remark = this.inRemark;
      tempInTran.transactionId = this.inTranId;
      tempInTran.date = this.inDate;
      tempTranList.push(tempInTran);
      this.billObj.brocker = this.brocker;
      this.billObj.tranList = tempTranList;
      this.billObj.chkCredit = this.chkCredit;
      this.billObj.receivedDate = this.receivedDate;
      if (this.saveOrupdate == "SAVE") {
        if (this.outBalance > 0 && this.inBalance > 0) {
          billService
            .saveBill(this.billObj)
            .then((response) => {
              this.outOneUnit = 0;
              this.inOneUnit = 0;
              this.outBalance = 0;
              this.inBalance = 0;
              this.outCommission = 0;
              this.inCommission = 0;
              this.inRemark = "";
              this.billListMethod();
              this.$swal({
                title: "Successful",
                text: "အောင်မြင်ပါ",
                type: "success",
                timer: 500,
              });
              this.$nextTick(() => this.$refs.refOut.focus());
              this.saveOrupdate = "SAVE";
              this.getBalanceMethod();
              this.inBalanceMethod();
            })
            .catch((error) => {
              this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
            });
        }
      } else {
        if (this.outBalance > 0 && this.inBalance > 0) {
          billService
            .updateBill(this.billObj)
            .then((response) => {
              this.outOneUnit = 0;
              this.inOneUnit = 0;
              this.outBalance = 0;
              this.inBalance = 0;
              this.outCommission = 0;
              this.inCommission = 0;
              this.inRemark = "";
              this.billListMethod();
              this.$swal({
                title: "Successful",
                text: "အောင်မြင်ပါ",
                type: "success",
                timer: 500,
              });
              this.saveOrupdate = "SAVE";
              this.$nextTick(() => this.$refs.refOut.focus());
              this.getBalanceMethod();
              this.inBalanceMethod();
            })
            .catch((error) => {
              this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
            });
        }
      }
    },
    download: function () {
      billService
        .billDownload(this.fromDate, this.toDate, this.allcashtype.typeId)
        .then((response) => {
          if (response.data.size == 0) {
            this.$swal({
              title: "Empty Data",
              text: "Data is Empty!",
              type: "warning",
              timer: 500,
            });
            return;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "bill.xlsx");
          // link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
    getBalanceMethod: function () {
      billService
        .getBalance(this.cashtype.typeId)
        .then((response) => {
          this.outTotalBalance = response;
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    inBalanceMethod: function () {
      billService
        .getBalance(this.incashtype.typeId)
        .then((response) => {
          this.inTotalBalance = response;
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
  },
  watch: {
    fromPicker() {
      this.fromDate = this.formatDate(this.fromPicker);
    },
    toPicker() {
      this.toDate = this.formatDate(this.toPicker);
    },
    cashtype() {
      if(this.cashtype.unitAmount != undefined){
          this.outUnitAmount = this.cashtype.unitAmount;
      }
      this.outBalance = Number(this.outOneUnit)*Number(this.outUnitAmount);
      this.getBalanceMethod();
    },
    incashtype() {
      if(this.incashtype.unitAmount != undefined){
          this.inUnitAmount = this.incashtype.unitAmount;
      }
      this.inBalance = Number(this.inOneUnit)*Number(this.inUnitAmount);
      this.inBalanceMethod();
    },
    receivePicker() {
      this.receivedDate = this.formatDate(this.receivePicker);
    },
    outOneUnit(){
        this.outBalance = Number(this.outOneUnit)*Number(this.outUnitAmount);
    },
    inOneUnit(){
        this.inBalance = Number(this.inOneUnit)*Number(this.inUnitAmount);
    }

  },
  components: { brockerDialog },
};
</script>
<style scoped>
.login-full {
  height: 85vh;
}
.v-data-table td,
.v-data-table th {
  padding: 0 0px !important;
}
</style>
